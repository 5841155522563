// @ts-nocheck

// Components
import SEO from '../components/seo'
import { Hero } from '../components/hero'
import { Faqs } from '../components/faqs'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { Features } from '../components/features'
// import Video from "../components/video";

// Assets
// import mutedVideo from "../videos/build.mp4";

const Home = (): JSX.Element =>{

  return (
    <>
      <SEO
        title="Welcome to Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article" />
      <Header />
      <main>
        {/* <Video src={mutedVideo} /> */}
        <Hero />
        <Features />
        <Faqs />
      </main>
      <Footer />
    </>
  )
}

export default Home