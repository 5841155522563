// Modules
import { useTranslation } from 'react-i18next';

// Components
import SEO from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import headshotJohn from '../images/headshots/john.png'
import headshotChristian from '../images/headshots/christian.jpg'

const AboutUs = (): JSX.Element =>{
  // Translation Hook
  const { t } = useTranslation(["aboutus"]);  

  return (
    <div className='min-h-screen'>
      <SEO
        title="About Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article" />
      <Header />
      <div className="mx-auto w-full px-4 md:max-w-7xl sm:px-8">
        <main className="flex flex-col my-12 gap-y-8">
          <div className='flex flex-col gap-y-4'>
            <h1 className='text-3xl tracking-wider font-bold'>
              {t("about-solocrete-header")}
            </h1>
            <p>
              {t("about-solocrete-text")}
            </p>
          </div>
          <div className='flex flex-col gap-y-4'>
            <h3 className='text-xl font-medium'>
              {t("about-cemex-header")}
            </h3>
            <p>
              {t("about-cemex-text")} <a href='https://www.cemexventures.com' target="_blank" rel="noreferrer">www.cemexventures.com</a>.
            </p>
          </div>
          <div className='flex flex-col gap-y-4'>
            <h3 className='text-xl font-medium'>
              {t("about-rvs-header")}
            </h3>
            <p>
              {t("about-rvs-text-1")}
            </p>
            <p>
              {t("about-rvs-text-2")} 
            </p>
          </div>
          <h2 className='text-3xl tracking-wider font-bold'>
            {t("founding-team-header")}
          </h2>
          <div className='flex flex-col lg:flex-row gap-x-4'>
            <img className="h-auto lg:h-52 w-auto" src={headshotJohn} alt="Solocrete" />
            <div className='flex flex-col gap-y-2'>
              <h2 className='mt-4 lg:-mt-1.5 text-xl font-medium'>
                {t("founding-team-ceo")}
              </h2>
              <p>
                {t("founding-team-ceo-bio-1")}
              </p>
              <p>
                {t("founding-team-ceo-bio-2")}
              </p>
              <p>
                {t("founding-team-ceo-bio-3")}
              </p>              
            </div>
          </div>
          <div className='flex flex-col lg:flex-row gap-x-4'>
            <img className="h-auto lg:christian w-auto" src={headshotChristian} alt="Solocrete" />
            <div className='flex flex-col gap-y-2'>
              <h2 className='mt-4 lg:-mt-1 text-xl font-medium'>
                {t("founding-team-cto")}
              </h2>
              <p>
                {t("founding-team-cto-bio-1")}
              </p>
              <p>
                {t("founding-team-cto-bio-2")}
              </p>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs