// Modules
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const languages = [
  { name: 'English', value: 'en', selected: false },
  { name: 'Español', value: 'es', selected: false },
]

export default function LanguageSelector() {
  const { i18n } = useTranslation();

  const [selected, setSelected] = useState(languages.filter(lng => lng.value === i18n.language)[0])

  const changeLanguage = (lng:any) => {
    setSelected(lng)
    i18n.changeLanguage(lng.value);
  };  

  return (
    <div className="w-auto z-50">
      <Listbox value={selected} onChange={changeLanguage}>
        <div className="relative">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 border text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 right-0 max-h-60 w-32 overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {languages.map((language, languageIdx) => (
                <Listbox.Option
                  key={languageIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-blue-100 text-blue-900 font-semibold' : 'text-gray-900 font-normal'
                    }`
                  }
                  value={language}
                >
                  {({ selected }) => {
                    // console.log("selected", selected)
                    return (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-semibold' : 'font-normal'
                        }`}
                      >
                        {language.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                    )}}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
