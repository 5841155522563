// Modules
import clsx from 'clsx'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// Components
import SEO from '../components/seo'
import { Button } from '../components/button'

// Assets
import logoSolocrete from '../images/logos/logo.svg'
import backgroundImage from '../images/background-auth.jpg'

// Type
type FormValues = {
  waitlist_id?: number
  referral_link?: string
  first_name: string
  last_name: string
  email: string
  phone: string
  referral_source?: string
  sms_opt_in_status: string
  sms_opt_in_date: number
}

const formClasses =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'

const Register = (): JSX.Element => {
  // Local State
  const [loading, setLoading] = useState<boolean>(false)
  const [waitlistData, setWaitlistData] = useState<any | null>()

  // Form Hook
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      referral_source: '',
      waitlist_id: 11904,
      referral_link: document.URL,
      sms_opt_in_status: undefined,
      sms_opt_in_date: new Date().getTime(),
    },
    mode: 'onChange',
  })

  // Navigation Hook
  const navigate = useNavigate()

  // Translation Hook
  const { t } = useTranslation(['register'])

  // Event Handlers
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    const record = {
      ...data,
      answers: [
        {
          question_value: 'How did you hear about us?',
          optional: true,
          answer_value: data.referral_source,
        },
      ],
    }

    setLoading(true)
    setWaitlistData(record)
    console.log('record', JSON.stringify(record, null, 2))
    setLoading(false)
  }

  return (
    <div className="relative flex h-[100vh] min-h-full justify-center md:px-12 lg:px-0">
      <SEO
        title="Register for Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article"
      />
      <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
        <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
          <div className="flex">
            <Link to="/" aria-label="Home">
              <img
                className="h-10 w-auto"
                src={logoSolocrete}
                alt="Solocrete"
              />
            </Link>
          </div>
          <h2 className="mt-10 text-lg font-semibold text-gray-900">
            {t('title')}
          </h2>
          {!waitlistData ? (
            <form
              id="Waitlist-Form"
              name="Waitlist-Form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor={'first_name'}
                    className="mb-3 block text-sm font-medium text-gray-700"
                  >
                    {t('first_name')}
                  </label>
                  <input
                    {...register('first_name', { required: true })}
                    aria-invalid={errors.first_name ? 'true' : 'false'}
                    className={formClasses}
                  />
                  {errors.first_name?.type === 'required' && (
                    <p role="alert" className="my-1 text-sm text-red-500">
                      Your first name is required.
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor={'last_name'}
                    className="mb-3 block text-sm font-medium text-gray-700"
                  >
                    {t('last_name')}
                  </label>
                  <input
                    {...register('last_name', { required: true })}
                    aria-invalid={errors.last_name ? 'true' : 'false'}
                    className={formClasses}
                  />
                  {errors.last_name?.type === 'required' && (
                    <p role="alert" className="my-1 text-sm text-red-500">
                      Your last name is required.
                    </p>
                  )}
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor={'email'}
                    className="mb-3 block text-sm font-medium text-gray-700"
                  >
                    {t('email_address')}
                  </label>
                  <input
                    {...register('email', {
                      required: 'An email address is required.',
                      validate: {
                        maxLength: (v) =>
                          v.length <= 50 ||
                          'The email should have at most 50 characters',
                        matchPattern: (v) =>
                          //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                          // eslint-disable-next-line no-empty-character-class
                          /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])+$/.test(
                            v,
                          ) || 'Email address must be a valid address',
                      },
                    })}
                    aria-invalid={errors.email ? 'true' : 'false'}
                    className={formClasses}
                  />
                  {errors.email?.message && (
                    <small role="alert" className="my-1 text-sm text-red-500">
                      {errors.email.message}
                    </small>
                  )}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor={'phone'}
                    className="mb-3 block text-sm font-medium text-gray-700"
                  >
                    {t('phone_number')}
                  </label>
                  <input
                    {...register('phone', {
                      required: 'A phone number is required.',
                      validate: {
                        maxLength: (v) => {
                          v = v.replaceAll('-', '')
                          v = v.replaceAll(' ', '')
                          return (
                            v.length <= 10 ||
                            'The email should have at most 10 characters'
                          )
                        },
                        matchPattern: (v) =>
                          /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/.test(
                            v,
                          ) ||
                          // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                          'Phone number must be a valid number',
                      },
                    })}
                    aria-invalid={errors.phone ? 'true' : 'false'}
                    className={formClasses}
                  />
                  {errors.phone?.message && (
                    <small role="alert" className="my-1 text-sm text-red-500">
                      {errors.phone.message}
                    </small>
                  )}
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor={'referral_source'}
                    className="mb-3 block text-sm font-medium text-gray-700"
                  >
                    {t('how_did_you_hear')}
                  </label>
                  <select
                    {...register('referral_source')}
                    className={clsx(formClasses, 'pr-8')}
                  >
                    <option value="google">{t('options.google')}</option>
                    <option value="word_of_mouth">
                      {t('options.word_of_mouth')}
                    </option>
                    <option value="referral">{t('options.referral')}</option>
                    <option value="other">{t('options.other')}</option>
                  </select>
                </div>

                <div className="col-span-full flex flex-row items-start space-x-2">
                  <input type="checkbox" {...register('sms_opt_in_status')} />
                  <label
                    htmlFor={'sms_opt_in_status'}
                    className="-mt-1 mb-3 block text-sm font-medium text-gray-700"
                  >
                    {t('sms_opt_in_status')}
                  </label>
                </div>

                <input
                  type="input"
                  {...register('sms_opt_in_date')}
                  className="hidden"
                />

                <div className="col-span-full">
                  <Button
                    type="submit"
                    variant="solid"
                    color="blue"
                    className="w-full"
                    disabled={loading}
                  >
                    <span>
                      {t('sign_up')}
                      <span aria-hidden="true">&rarr;</span>
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          ) : (
            <div className="flex flex-col gap-y-8 text-gray-700">
              <div className="mt-4">
                Thank you for signing up. We will reach out to you when we are
                ready to launch.
              </div>
              <div className="">
                <button
                  type="button"
                  onClick={() => {
                    setWaitlistData(null)
                    reset()
                    navigate('/')
                  }}
                  className="w-full rounded-md bg-indigo-600 px-3 py-2 text-white transition duration-300 hover:bg-indigo-700"
                >
                  Return to home page
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
      <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={backgroundImage}
          alt=""
        />
      </div>
    </div>
  )
}

export default Register
