// Modules
import { Suspense } from 'react';
import { Helmet } from "react-helmet-async";
import { Route, Routes } from 'react-router-dom'

// Components
import Home from './pages/home';
import AboutUs from './pages/about';
import ContactUs from './pages/contact';
import Register from './pages/register';

export default function App() {
  // return (
  //   <Suspense fallback={<Loader />}>
  //     <Page />
  //   </Suspense>
  // );
  return (
    <Suspense fallback={""}>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
            default-src 'self';
            script-src 'self' https://js.hs-banner.com https://js.usemessages.com https://js.hs-analytics.net https://js.hscollectedforms.net;
            connect-src 'self' https://vitals.vercel-insights.com https://api.getwaitlist.com https://js.hs-banner.com https://js.usemessages.com https://js.hs-analytics.net https://js.hscollectedforms.net https://forms.hscollectedforms.net https://track.hubspot.com https://app.hubspot.com https://api.hubspot.com;
            font-src fonts.gstatic.com;
            img-src 'self' https://track.hubspot.com https://forms.hsforms.com;
            style-src 'self' fonts.googleapis.com sha256-DnaqpZzZnNOTsPfM/jmZ/lzX6hF+6P856AFp5oXYGFI=;
            frame-src 'self' https://app.hubspot.com;
          `}
        ></meta>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="register" element={<Register />} />
      </Routes>
    </Suspense>
  );
}