// Modules
import { useTranslation } from 'react-i18next'

// Components
import { Container } from './container'
import backgroundImage from '../images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'phone-orders',
      answer: 'phone-orders-answer',
    },
    {
      question: 'pay-by-invoice',
      answer: 'pay-by-invoice-answer',
    },
    {
      question: 'other-materials',
      answer: 'other-materials-answer',
    },
  ],
]

export function Faqs() {
  const { t } = useTranslation(['faqs'])

  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-4xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            {t('faq')}
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            {t('faq_subheadline')}
          </p>
        </div>
        {/* <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"> */}
        <ul className="mx-auto mt-16 grid w-full grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-1">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {t(`${faq.question}`)}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">
                      {t(`${faq.answer}`)}
                    </p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
