// Modules
import clsx from 'clsx'
import { Fragment } from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Popover, Transition } from '@headlessui/react'

// Components
import { Button } from './button'
import { Container } from './container'
import LanguageSelector from './languageselector'
// import logoSolocrete from '../images/logos/logo.svg'
import logoSolocrete from '../images/logos/solocrete-supply.svg'

function MobileNavLink({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) {
  return (
    <Popover.Button as={Link} to={href} className="block w-full p-2">
      {children}
    </Popover.Button>
  )
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  const { t } = useTranslation(["header"]);

  return (
    <Popover>
      <Popover.Button
        className="relative z-50 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 z-50 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="/about">{t("about-us")}</MobileNavLink>
            <MobileNavLink href="/contact">{t("contact-us")}</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href="/register">{t("wait-list-button")}</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header() {
  const { t } = useTranslation(["header"]);
  
  return (
    <header className="py-3 bg-white border-b border-slate-100 shadow-sm">
      <Container>
        <nav className="relative flex justify-between">
          <div className="flex gap-x-12 mb-2">
            <div className="flex flex-row items-center justify-center h-auto">
              <Link to="/" aria-label="Home">
                <img className="h-8 w-auto mt-2" src={logoSolocrete} alt="Solocrete" />
                </Link>
                {/* <Link to="/" aria-label="Home">
                  <div className='logotext-sm lg:logotext-lg mt-1 md:mt-0.5 text-slate-800 font-thin tracking-wider'>
                    supply
                  </div>
                </Link> */}
            </div>
            <div className="flex items-center gap-x-2 sm:gap-x-4">
              <div className="hidden lg:flex lg:gap-x-6 mt-2 font-normal tracking-wide">
                <Link className="inline-block rounded-lg px-4 py-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900" to="/about">{t("about-us")}</Link>
                <Link className="inline-block rounded-lg px-4 py-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900" to="/contact">{t("contact-us")}</Link>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-2 sm:gap-x-5">
            <div className="hidden lg:flex">
              <Button to="/register" color="blue">
                {t("wait-list-button")}
              </Button>
            </div>
            <LanguageSelector />
            <div className="-mr-1 lg:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}
