// @ts-nocheck

// Modules
import { Component } from "react";

export default class Video extends Component {
    state = {
      html: "",
    };
    componentDidMount() {
      const src = this.props.src;
      const html = `
          <video 
          preload='auto'
          autoPlay
          playsInline
          loop
          muted>
            <source src=${src} />
          </video>
        `;
      this.setState({ html });
    }
    render() {
      return <div 
      className="absolute z-0 video-cover" dangerouslySetInnerHTML={{ __html: this.state.html }}>
        {/* <video ></video> */}
      </div>;
    }
  }